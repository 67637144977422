.elipse-container {
	top: -50vh;
	left: -15vw;
}
@keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

.elipse1 {
	-webkit-animation: rotation 36s infinite linear;
	animation: rotation 36s infinite linear;
}

@media screen and (max-width: 768px) {
	.elipse-container {
		top: -35vh;
		left: -55vw;
	}
}
