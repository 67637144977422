.player {
	width: 100%;
	height: 600px;
}
@media screen and (max-width: 767px) {
	.player {
		width: 100%;
		height: 240px;
	}
}
.elipse2-container {
	top: 10%;
	right: -25vw;
}
@media screen and (max-width: 768px) {
	.elipse2-container {
		top: 10%;
		right: -100vw;
	}
}
