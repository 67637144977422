.hero {
	min-height: -moz-calc(100vh);
	min-height: -webkit-calc(100vh);
	min-height: calc(100vh);
}
.video {
	position: absolute;
	top: 0;
	left: 0;
	min-width: 100%;
	height: 100%;
	min-height: calc(100vh);
	object-fit: cover;
	opacity: 0.6;
}

.whatsapp-button {
	background: linear-gradient(266.54deg, #00ba5f 0%, #008343 100%);
}
.whatsapp-button:hover {
	background: #008343;
}
