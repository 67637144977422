@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

html {
	scroll-behavior: smooth;
}

body {
	font-family: "Manrope", sans-serif;
}
.content-wrapper {
	max-width: 1800px;
	padding: 0 20px;
	margin-left: auto;
	margin-right: auto;
}
.oval {
	position: relative;
	overflow: hidden;
}
.oval:before {
	content: "";
	position: absolute;
	top: 25%;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	border-radius: 50% 50% 0 0/20% 20% 0 0;
	transform: scale(1.5);
}

.minus-margin {
	margin-top: -80px;
	margin-left: -40px;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
